import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Aboutus() {

  useEffect(()=>{
    document.title = 'Elixir | About Us';
  }, []);

  return (
    <>
    <section className="aboutSec">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">We Help <span className="span2 home-banner-text-animate">Your Business</span> With Creative Idea</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">Established in 2010, Elixir & AR Graphics has proudly completed 15 years of delivering exceptional services in Saudi Arabia. As a leading company, we specialize in branding, graphic design, signage, printing, event management, exhibitions, corporate gifts, web design, and trading. Our commitment to quality and innovation has made us a trusted partner for diverse industries. With a proven track record, we continue to set benchmarks in creativity and professional excellence.</p>
          </Col>
        </Row>
      </Container>
      </section>
      <section className="aboutSec-img">
      <Container>
        <Row>
          <Col lg={3}>
            <img src={require('../assets/img/about-inner-1.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
          </Col>
          <Col lg={6}>
            <img src={require('../assets/img/about-inner-2.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
          </Col>
          <Col lg={3}>
            <img src={require('../assets/img/about-inner-3.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
          </Col>
        </Row>
      </Container>
      </section>
      <section className="aboutSec-vision">
      <Container>
        <Row>
          <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
            <h3>Mission</h3>
            <p>At <strong>Elixir & AR Graphics</strong>, our mission is to revolutionize the way brands, businesses, and individuals connect with their audiences through cutting-edge Augmented Reality (AR) and visually stunning graphic designs. We are driven by the passion to create immersive, engaging, and impactful experiences that leave a lasting impression.</p>
          </Col>
          <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
          <h3>Vision</h3>
          <p>At <strong>Elixir & AR Graphics</strong>, we envision a world where creativity, technology, and connection converge to reshape how people experience design and storytelling. We aspire to lead the way in transforming traditional visual communication into immersive, interactive, and unforgettable experiences.</p>
          </Col>
        </Row>
      </Container>
      </section>
    </>
  );
}

export default Aboutus;
