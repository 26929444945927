import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import log01 from '../assets/img/logo-1.svg';
import log02 from '../assets/img/logo-1.svg';
import rightFacebook from '../assets/img/facebook-icon.svg';
import rightTwitter from '../assets/img/twitter-icon.svg';
import rightYoutube from '../assets/img/youtube-icon.svg';
import rightInstagram from '../assets/img/instagram-icon.svg';
import brochurePdf from '../assets/img/pdf/brochure.pdf';


function Navigation() {
  const [navbar, setNavbar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [expanded, setExpanded] = useState(false); // State to manage Navbar.Toggle

  // Toggle navbar background on scroll
  const changeBackground = () => {
    setNavbar(window.scrollY >= 10);
  };

  // Track window resizing to detect mobile or tablet views
  const updateView = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    window.addEventListener('resize', updateView);

    return () => {
      window.removeEventListener('scroll', changeBackground);
      window.removeEventListener('resize', updateView);
    };
  }, []);

  // Toggle dropdown on click for mobile view
  const handleDropdownToggle = () => {
    if (isMobile) setShowDropdown(!showDropdown);
  };

  // Collapse Navbar when a NavLink is clicked
  const handleNavClick = () => {
    setExpanded(false);
  };

// Dropdown Active Start
  const location = useLocation(); // Get the current location

  // Check if the current path matches any of the Services submenu paths
  const isServicesActive = [
    "/elixir-new-1/brandingandgraphicdesign",
    "/elixir-new-1/Signageoutdoor",
    "/elixir-new-1/Signageindoor",
    "/elixir-new-1/Printing",
    "/elixir-new-1/EventManagement",
    "/elixir-new-1/ExhibitionBooths",
    "/elixir-new-1/CorporateGifts",
    "/elixir-new-1/WebDesign",
    "/elixir-new-1/Trading",
  ].some((path) => location.pathname === path);
// Dropdown Active End  

  return (
    <div className="navigation fixed-top">
      <ScrollToTop />
      <Navbar expand="lg" expanded={expanded} className={navbar ? 'navbar active' : 'navbar'}>
        <Container>
          <Navbar.Brand href="#home" className="me-auto">
            <Link to="/elixir-new-1">
              <img id="one" src={log01} alt="Elixir" />
              <img id="two" src={log01} alt="Elixir" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : true)}
          />
          <Navbar.Collapse id="basic-navbar-nav" className="me-auto m-auto">
            <Nav className="ms-auto navmenuCenter">
              <NavLink className="nav-link" to="/elixir-new-1" onClick={handleNavClick} end>
                Home
              </NavLink>
              <NavLink className="nav-link" to="/elixir-new-1/aboutus" onClick={handleNavClick} end>
                About Us
              </NavLink>
              <NavDropdown
                className={`dropdown ${isServicesActive ? "active" : ""}`}
                title="Services"
                onClick={handleDropdownToggle} end
              >
                <NavDropdown.Item
                  as={Link}
                  className="dropdown-item"
                  to="/elixir-new-1/brandingandgraphicdesign"
                  onClick={handleNavClick} end
                >
                  Branding and Graphic Design
                </NavDropdown.Item>
                <NavDropdown title="Signage" className="dropend serviceMenu" end>
                  <NavDropdown.Item
                    className="dropdown-item"
                    as={Link}
                    to="/elixir-new-1/Signageoutdoor"
                    onClick={handleNavClick}
                  >
                    - Outdoor
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="dropdown-item"
                    as={Link}
                    to="/elixir-new-1/Signageindoor"
                    onClick={handleNavClick}
                  >
                    - Indoor
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown.Item className="dropdown-item" as={Link} to="/elixir-new-1/printingservices" onClick={handleNavClick} end>Printing</NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" as={Link} to="/elixir-new-1/Eventservices" onClick={handleNavClick}  end>Event Management</NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" as={Link} to="/elixir-new-1/Exhibitionservices" onClick={handleNavClick}  end>Exhibition Booths</NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" as={Link} to="/elixir-new-1/Corporateservices" onClick={handleNavClick} end>Corporate Gifts</NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" as={Link} to="/elixir-new-1/Webdesignservices" onClick={handleNavClick} end>Web Design and Development</NavDropdown.Item>
                <NavDropdown.Item className="dropdown-item" as={Link} to="/elixir-new-1/Tradingservices" onClick={handleNavClick} end>Trading</NavDropdown.Item>

              </NavDropdown>
              <NavLink className="nav-link" to="/elixir-new-1/project" onClick={handleNavClick} end>
                Project
              </NavLink>
              <NavLink className="nav-link" to="/elixir-new-1/clients" onClick={handleNavClick} end>
                Clients
              </NavLink>
              <NavLink className="nav-link" to="/elixir-new-1/contactus" onClick={handleNavClick} end>
                Contact Us
              </NavLink>
            </Nav>
            <a
              href={brochurePdf}
              className="primaryBtn ms-auto"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleNavClick}
            >
              Brochure
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <section className="leftSocial" data-aos="zoom-in" data-aos-duration="3000">
        <a href="https://www.facebook.com/people/ElixirWorldWide/100069599795667/" target="_blank" rel="noopener noreferrer">
          <img src={rightFacebook} alt="Facebook" />
        </a>
        <a href="https://x.com/i/flow/login?redirect_after_login=%2Felixirworldwide" target="_blank" rel="noopener noreferrer">
          <img src={rightTwitter} alt="Twitter" />
        </a>
        <a href="https://www.youtube.com/elixirworldwide" target="_blank" rel="noopener noreferrer">
          <img src={rightYoutube} alt="YouTube" />
        </a>
        <h4>Follow</h4>
      </section>
    </div>
  );
}

export default Navigation;