import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

function Clients() {

  useEffect(()=>{
      document.title = 'Elixir | Our Clients';
    }, []);

  return (
    <>
    <section className="clientSec">
      <Container>
        <Row data-aos="fade-up" data-aos-duration="3000">
          <Col lg={12}>
            <h1>Thanking <span className="span2 home-banner-text-animate">Our Clients</span> for their Continued Trust!</h1>
            <p>We deeply appreciate the continued trust our clients place in us, which drives us to always exceed expectations. Their unwavering support and confidence have played a vital role in our growth and success over the years. We understand that trust is earned, and we are committed to upholding that trust through consistent excellence in every aspect of our work. Our clients' loyalty encourages us to innovate and refine our services to meet their evolving needs. We are passionate about building lasting relationships and delivering results that make a difference. Thank you for being an essential part of our journey, and for allowing us to serve you. We look forward to continuing this partnership and achieving even greater success together.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="clientlogoSec">
      <Container>
        <Row data-aos="fade-up" data-aos-duration="3000">          
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-1.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-2.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-3.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-4.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-5.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-6.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-7.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-8.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-9.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-10.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-11.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
          <Col lg={2} md={4} xs={6}>
            <a href="#">
              <img src={require('../assets/img/client-logo-12.png')} className="img-fluid" alt="elixir" />
            </a>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Clients;
