import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Project() {

  useEffect(()=>{
    document.title = 'Elixir | Our Projects';
  }, []);

  const [key, setKey] = useState('home');

  return (
    <>
    <section className="projectSec">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Transforming <span className="span2 home-banner-text-animate">Creative Projects</span> and Innovation</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">We revolutionize design and printing by blending creativity with technology, delivering solutions that enhance storytelling, brand engagement, and high-quality prints. Our approach merges artistic expression with modern techniques to leave lasting impressions and redefine printing possibilities.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="projectTab">
      <Container>
        <Row data-aos="fade-up" data-aos-duration="3000">
          <Col lg={12}>
          <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"              
            >
              <Tab eventKey="home" title="Digital">
                <Row>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/digital/digital-project-1.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Digital Brand 1</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/digital/digital-project-2.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Digital Brand 2</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/digital/digital-project-3.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Digital Brand 3</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/digital/digital-project-4.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Digital Brand 4</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/digital/digital-project-5.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Digital Brand 5</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/digital/digital-project-6.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Digital Brand 6</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/digital/digital-project-7.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Digital Brand 7</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/digital/digital-project-8.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Digital Brand 8</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="exhibitions" title="Exhibitions">
                <Row>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/exhibitions/exhibitions-project-1.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Exhibitions 1</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/exhibitions/exhibitions-project-2.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Exhibitions 2</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/exhibitions/exhibitions-project-3.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Exhibitions 3</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/exhibitions/exhibitions-project-4.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Exhibitions 4</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="events" title="Events">
                <Row>
                    <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                      <div className="projectTab-box">
                        <img src={require('../assets/img/project/events/events-project-1.jpg')} className="img-fluid" alt="elixir" />
                        <div className="projectTab-box-content">
                          <h4>Events 1</h4>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                      <div className="projectTab-box">
                        <img src={require('../assets/img/project/events/events-project-2.jpg')} className="img-fluid" alt="elixir" />
                        <div className="projectTab-box-content">
                          <h4>Events 2</h4>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                      <div className="projectTab-box">
                        <img src={require('../assets/img/project/events/events-project-3.jpg')} className="img-fluid" alt="elixir" />
                        <div className="projectTab-box-content">
                          <h4>Events 3</h4>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                      <div className="projectTab-box">
                        <img src={require('../assets/img/project/events/events-project-4.jpg')} className="img-fluid" alt="elixir" />
                        <div className="projectTab-box-content">
                          <h4>Events 4</h4>
                        </div>
                      </div>
                    </Col>
                  </Row>
              </Tab>              
              <Tab eventKey="printing" title="Printing">
                <Row>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/printing/printing-project-1.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Printing 1</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/printing/printing-project-2.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Printing 2</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/printing/printing-project-3.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Printing 3</h4>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <div className="projectTab-box">
                      <img src={require('../assets/img/project/printing/printing-project-4.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Printing 4</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="websites" title="Websites">
              <Row>
                  <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <a href="#" className="projectTab-box">
                      <img src={require('../assets/img/project/websites/websites-project-1.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Websites 1</h4>
                      </div>
                    </a>
                  </Col>
                  <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <a href="#" className="projectTab-box">
                      <img src={require('../assets/img/project/websites/websites-project-2.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Websites 2</h4>
                      </div>
                    </a>
                  </Col>
                  <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="3000">
                    <a href="#" className="projectTab-box">
                      <img src={require('../assets/img/project/websites/websites-project-3.jpg')} className="img-fluid" alt="elixir" />
                      <div className="projectTab-box-content">
                        <h4>Websites 3</h4>
                      </div>
                    </a>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Project;
