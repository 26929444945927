import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Corporateservices() {
  useEffect(()=>{
    document.title = 'Elixir | Corporate Gifts - Services';
  }, []);
  return (
    <>
    <section className="brandServices">
          <Container>
            <Row>
              <Col lg={6}>
                <h1 data-aos="zoom-in" data-aos-duration="3000">Comprehensive <span className="span2 home-banner-text-animate">Corporate Event</span> and Gifting</h1>
              </Col>
              <Col lg={6}>
                <p data-aos="fade-up" data-aos-duration="3000">Make your corporate events unforgettable with our expert services, including event planning, creative setups, and unique gifts like customized awards and branded keepsakes. We ensure quality and attention to detail, helping you celebrate achievements and leave a lasting impression.</p>
              </Col>
            </Row>
          </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/corporate-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="eventsServices-one">
      <Container>
        <Row>
          <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
            <h3>Expert Corporate Event Management & Gifting Solutions</h3>
            <p>Transform your corporate events with our comprehensive event management and gifting services. From seamless event planning and creative setups to personalized gifts like customized awards and branded keepsakes, we ensure every detail aligns with your vision. We focus on delivering quality and making your event impactful, leaving a lasting impression on clients, employees, and stakeholders.</p>
          </Col>
          <Col lg={6}>
            <img src={require('../assets/img/corporate-services-img-1.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
          </Col>
        </Row>
      </Container>
    </section>
    <section className="eventsServices-two">
      <Container>
        <div className="eventsServices-two-box">
          <Row>
            <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
              <h3>Custom Corporate Events & Awards Gifting Services</h3>
              <p>Enhance your corporate celebrations with our tailored event and gifting services. Whether it’s an awards ceremony, team celebration, or corporate gathering, we provide everything from meticulous planning to customized awards, hampers, and branded gifts. Our commitment to quality ensures your event is a memorable success, fostering lasting relationships and recognizing achievements in style.</p>
            </Col>
            <Col lg={6}>
              <img src={require('../assets/img/corporate-services-img-2.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
      <Container>
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div className="projectTab-box">
              <img src={require('../assets/img/project/digital/digital-project-6.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Digital Brand 6</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Corporateservices;