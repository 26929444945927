import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import homebannerIcon from "../assets/img/home-banner-icon-1.svg";
import projectlinkIcon1 from "../assets/img/project-link-icon1.svg";
import sliderpreviousIcon from "../assets/img/slider-previous-icon.svg";
import slidernextIcon from "../assets/img/slider-next-icon.svg";


function Home() {

  useEffect(()=>{
    document.title = 'Elixir | Home';
  }, []);

  // Scroll Counter Up Start
  const [counterOn, setCounterState] = useState(false); 
  // Scroll Counter Up End

  // Project Slider Start
  const PrevArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={sliderpreviousIcon} className="img-fluid" alt="elixir" />
    </div>
  );
  
  const NextArrow = ({ className, style, onClick }) => (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={slidernextIcon} className="img-fluid" alt="elixir" />
    </div>
  );

  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    focusOnSelect: true,
    pauseOnHover:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  // Project Slider End

  // Team Slider Start
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,  
    focusOnSelect: true,
    pauseOnHover:false,  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  // Team Slider End

  // Testimonials Slider Start
  var settings3 = {
    dots: true,
    infinite: true,
    speed: 600,
    cssEase: 'linear',
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    // prevArrow: <PrevArrow />,
    // nextArrow: <NextArrow />,
    focusOnSelect: true,
    pauseOnHover:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  // Testimonials Slider End

  return (
    <>    
    <section className="homeCategory">
      <Container>
          <Row>            
            <Col lg={3} md={6}>
              <a href="#" className="homeCategory-box1" data-aos="fade-up" data-aos-duration="3000">
                <h4>Digital</h4>
                <p>Creative digital ad design tailored for impact.</p>
                <img src={require('../assets/img/home-banner-img-1.jpg')} className="img-fluid" alt="elixir"  />
              </a>
            </Col>  
            <Col lg={6} md={6}>
              <div className="homeCategory-box2" data-aos="fade-up" data-aos-duration="3000">
              <h1>
                <span className="span1">Your All-in-One</span> 
                <span className="span2 home-banner-text-animate">Advertising & Printing</span> 
                <span className="span3">Solution</span></h1>
                <p>Everything begins here. Let’s chat.</p>
              </div>
            </Col>          
            <Col lg={3} md={6}>
              <a href="#" className="homeCategory-box1" data-aos="fade-up" data-aos-duration="3000">
                <h4>Printing</h4>
                <p>Eye-catching printing designs crafted.</p>
                <img src={require('../assets/img/home-banner-img-2.jpg')} className="img-fluid" alt="elixir"  />
              </a>
            </Col>  
            <Col lg={6} md={6}>
              <a href="#" className="homeCategory-box3" data-aos="fade-up" data-aos-duration="3000">
                <h4 className="mb-3">Event Management</h4>
                <img src={require('../assets/img/home-banner-img-3.jpg')} className="img-fluid" alt="elixir"  />
              </a>
            </Col>
            <Col lg={3} md={6}>
              <a href="#" className="homeCategory-box1" data-aos="fade-up" data-aos-duration="3000">
                <h4>Web Design</h4>
                <p>Strategy-based marketing communication</p>
                <img src={require('../assets/img/home-banner-img-4.jpg')} className="img-fluid" alt="elixir"  />
              </a>
            </Col>  
            <Col lg={3} md={6}>
              <a href="#" className="homeCategory-box1" data-aos="fade-up" data-aos-duration="3000">
                <h4>Trading</h4>
                <p>Strategy-based marketing communication</p>
                <img src={require('../assets/img/home-banner-img-5.jpg')} className="img-fluid" alt="elixir"  />
              </a>
            </Col>  
        </Row>
      </Container>
    </section>
    <section className="homeCounter">
      <Container>
        <div className="homeCounter-box">
         
          <div className="homeCounter-box1">    
            <Row>      
              <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
                <h3>A Complete Communication Solution</h3>
              </Col>
              <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
                <p>Established in 2010, Elixir & AR Graphics has proudly completed 15 years of delivering exceptional services in Saudi Arabia. As a leading company, we specialize in branding, graphic design, signage,...</p>
                <a href="/elixir-new-1/aboutus" className="primaryBtn">Read more..</a>
              </Col>
            </Row>
          </div>          
          <hr />
          <div className="homeCounter-box2">
            <Row>
              <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
                <h3>Our Milestone</h3>
              </Col>
              <Col lg={6} data-aos="fade-up" data-aos-duration="3000">              
                  <ScrollTrigger className="homeCounter-counter" onEnter={()=> setCounterState(true)} onExit={()=> setCounterState(false)} >
                    <h4>
                        {counterOn && <CountUp start={0} end={700} duration={2} delay={0}/>}
                        <span className="homeCounter-plus">+</span>
                        <span className="homeCounter-title">Products Live</span>
                    </h4>
                    <h4>
                        {counterOn && <CountUp start={0} end={20} duration={4} delay={0}/>}
                        <span className="homeCounter-plus">+</span>
                        <span className="homeCounter-title">Specialist</span>  
                    </h4>
                    <h4>
                        {counterOn && <CountUp start={0} end={23} duration={4} delay={0}/>}
                        <span className="homeCounter-plus">+</span>
                        <span className="homeCounter-title">Years of Experience</span>  
                    </h4>
                </ScrollTrigger>  
              </Col>          
            </Row>
          </div>
        </div>
      </Container>
    </section>
    <section className="homeService">
      <Container>
        <div className="homeService-img">
          <Row>     
            <h2 data-aos="fade-up" data-aos-duration="3000">Why Choose Us</h2>       
            <Col lg={4} md={6}>              
              <div className="homeService-img-text" data-aos="fade-up" data-aos-duration="3000">
                <h4><span>01</span> Customer Support</h4>
                <p>We are highly dedicated to offering exceptional and reliable customer service always.</p>
              </div>
            </Col>
            <Col lg={4} md={6}>  
              <div className="homeService-img-text" data-aos="fade-up" data-aos-duration="3000">
                <h4><span>02</span> Skilled Team</h4>
                <p>Our team is composed of industry experts with extensive knowledge and experience in the field.</p>
              </div>
            </Col>
            <Col lg={4} md={6}>  
              <div className="homeService-img-text" data-aos="fade-up" data-aos-duration="3000">
                <h4><span>03</span> Quality Products</h4>
                <p>We offer competitive pricing without compromising on the high-end quality of our products.</p>
              </div>
            </Col>
            <Col lg={4} md={6}>              
              <div className="homeService-img-text" data-aos="fade-up" data-aos-duration="3000">
                <h4><span>04</span> Global Benchmarks</h4>
                <p>We comprehend and consistently adhere to international standards to ensure high-quality results.</p>
              </div>
            </Col>
            <Col lg={4} md={6}>              
              <div className="homeService-img-text" data-aos="fade-up" data-aos-duration="3000">
                <h4><span>05</span> Professional Work</h4>
                <p>Our qualified team works professionally to deliver outstanding results with expertise and dedication.</p>
              </div>
            </Col>
            <Col lg={4} md={6}>              
              <div className="homeService-img-text" data-aos="fade-up" data-aos-duration="3000">
                <h4><span>06</span> On-Time Delivery</h4>
                <p>Our dedicated team efficiently completes projects and consistently and high-quality products on time.</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="homeProjects">
      <Container>
        <Row>
          <div className="homeProjects-title">
          <div>
            <h2 data-aos="fade-up" data-aos-duration="3000">Our Projects</h2>
          </div>
          <div>
            <a href="#" className="primaryBtn" data-aos="zoom-in" data-aos-duration="3000">Explore more..</a>
          </div>
        </div>
        <Col lg={12} data-aos="zoom-in" data-aos-duration="3000">
          <Slider {...settings1}>
            <div className="homeProjects-box">
              <img src={require('../assets/img/project-img1.jpg')} className="img1 img-fluid" alt="elixir" />
              <div className="homeProjects-text">
                <div>
                  <h4>Elixir Real Estate</h4>
                  <p>Elixir is a dynamic branding and print advertising company dedicated to creating impactful visual..</p>
                </div>
                <div>
                  <a href="#"><img src={projectlinkIcon1} className="img2 img-fluid" alt="elixir" /></a>
                </div>
              </div>
            </div> 
            <div className="homeProjects-box">
              <img src={require('../assets/img/project-img2.jpg')} className="img1 img-fluid" alt="elixir" />
              <div className="homeProjects-text">
                <div>
                  <h4>Branding Company</h4>
                  <p>Elixir is a dynamic branding and print advertising company dedicated to creating impactful visual..</p>
                </div>
                <div>
                  <a href="#"><img src={projectlinkIcon1} className="img2 img-fluid" alt="elixir" /></a>
                </div>
              </div>
            </div> 
            <div className="homeProjects-box">
              <img src={require('../assets/img/project-img3.jpg')} className="img1 img-fluid" alt="elixir" />
              <div className="homeProjects-text">
                <div>
                  <h4>Marketing Services</h4>
                  <p>Elixir is a dynamic branding and print advertising company dedicated to creating impactful visual..</p>
                </div>
                <div>
                  <a href="#"><img src={projectlinkIcon1} className="img2 img-fluid" alt="elixir" /></a>
                </div>
              </div>
            </div> 
            <div className="homeProjects-box">
              <img src={require('../assets/img/project-img4.jpg')} className="img1 img-fluid" alt="elixir" />
              <div className="homeProjects-text">
                <div>
                  <h4>Web Design</h4>
                  <p>Elixir is a dynamic branding and print advertising company dedicated to creating impactful visual..</p>
                </div>
                <div>
                  <a href="#"><img src={projectlinkIcon1} className="img2 img-fluid" alt="elixir" /></a>
                </div>
              </div>
            </div>  
          </Slider>
        </Col> 
        </Row>
      </Container>
    </section>
    <section className="homeReal">      
      <Container>
        <div className="homeReal-box">
          <Row>
            <Col lg={12}>
              <h2 data-aos="zoom-in" data-aos-duration="3000">
                <span className="span1">Real People +</span>
                <span className="span2">Real Passion +</span>
                <span className="span3">Real Experience +</span>
                <span className="span4"><span>=</span> Elixir & AR Graphics Team</span>
              </h2>
            </Col>
          </Row>
        </div>
      </Container>      
    </section>
    <section className="clientlogo">
    <Container>
      <Row>
        <Col lg={12}>
          <h2 data-aos="fade-up" data-aos-duration="3000">Our Clients</h2>
          <Col lg={12} data-aos="zoom-in" data-aos-duration="3000">
          <Slider {...settings2}>
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-1.png')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-2.png')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-3.png')} className="img1 img-fluid" alt="elixir" />
            </div> 
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-4.png')} className="img1 img-fluid" alt="elixir" />
            </div>  
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-5.png')} className="img1 img-fluid" alt="elixir" />
            </div>  
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-6.png')} className="img1 img-fluid" alt="elixir" />
            </div>  
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-7.png')} className="img1 img-fluid" alt="elixir" />
            </div>  
            <div className="clientlogo-box">
              <img src={require('../assets/img/client-logo-8.png')} className="img1 img-fluid" alt="elixir" />
            </div>  
          </Slider>
        </Col> 
        </Col>
      </Row>
    </Container>
    </section>
    </>
  );
}

export default Home;
