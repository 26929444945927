import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import rightWhatsapp from '../assets/img/whatsapp-icon.svg';
import bgLogo2 from '../assets/img/bg-logo-2.svg';

function Footer() {
  return (
    <>
    <footer>
      <Container>
        <Row>
          <Col lg="7" md={12}>
            <div className="footerLink" data-aos="zoom-in" data-aos-duration="3000">
              <Link to="/elixir-new-1">Home</Link>
              <Link to="/elixir-new-1/aboutus">About Us</Link>
              <Link to="/elixir-new-1/brandingandgraphicdesign">Services</Link>
              <Link to="/elixir-new-1/project">Project</Link>
              <Link to="/elixir-new-1/clients">Clients</Link>
              <Link to="/elixir-new-1/contactus">Contact Us</Link>
            </div>
          </Col>
          <Col lg="5" md={12}>
            <div className="footerMail" data-aos="zoom-in" data-aos-duration="3000">
              <a href="mailto:Anand@elixirworldwide.com">anand@elixirworldwide.com</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md={6}>
            <div className="footerAddress" data-aos="fade-up" data-aos-duration="3000">
              <h4>Address</h4>
              <p><span>Off:</span> P.O.Box:2834, Alkhobar 31952,</p>
              <p><span>Prod.Off:</span> Dammam 31463, Saudi Arabia</p>
              <p><span>Mobile:</span> <a href="tel:+966552301809">+966 55 230 1809</a></p>
            </div>
          </Col>
          <Col lg="3" md={6}>
            <div className="footerMail-id" data-aos="fade-up" data-aos-duration="3000">
              <h4>Contact</h4>
              <p><span>Enquiry:</span> <a href="mailto:elixirworldwide@gmail.com">elixirworldwide@gmail.com</a></p>
              <p><span>Job:</span> <a href="mailto:jobs@elixirworldwide.com">jobs@elixirworldwide.com</a></p>
            </div>
          </Col>
          <Col lg="3" md={6}>
            <div className="footerLogo" data-aos="zoom-in" data-aos-duration="3000">
              <img src={bgLogo2} className="img-fluid" alt="elixir" />
            </div>
          </Col>
          <Col lg="3" md={6}>
            <div className="footerSocial" data-aos="fade-up" data-aos-duration="3000">              
              <p><a href="https://www.facebook.com/people/ElixirWorldWide/100069599795667/" target="_blank">Facebook</a></p>
              <p><a href="https://x.com/i/flow/login?redirect_after_login=%2Felixirworldwide" target="_blank">Twitter</a></p>
              <p><a href="https://www.youtube.com/elixirworldwide" target="_blank">Youtube</a></p>
              {/* <p><a href="#">Instagram</a></p> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="7" md={6}>
            <div className="footerCopy">
              <p>Copyright © ELIXIR Advertising & AR Graphics</p>
            </div>
          </Col>
          <Col lg="5" md={6}>
            <div className="footerTerms">
              <p><a href="#">Terms & Conditions</a></p>
              <p><a href="#">Privacy Policy</a></p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>   
    <section className="leftWhatsapp">
      <a href="https://wa.me/966552301809?text=Hello%2C%20I%27d%20like%20to%20chat!" target="_blank" rel="noopener noreferrer">
        <img src={rightWhatsapp} className="img-fluid" alt="elixir" />
      </a>
    </section>
    </>
  );
}

export default Footer;
