import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Printingservices() {
  useEffect(()=>{
    document.title = 'Elixir | Printing - Services';
  }, []);
  return (
    <>
    <section className="printingServices">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Transforming <span className="span2 home-banner-text-animate">Creative Printing</span> and Innovation</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">We revolutionize design and printing by blending creativity with technology, delivering solutions that enhance storytelling, brand engagement, and high-quality prints. Our approach merges artistic expression with modern techniques to leave lasting impressions and redefine printing possibilities.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/printing-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="printingServices-one">
      <Container>
        <div className="printingServices-one-box">
          <Row>
            <Col lg={12}>
              <div className="printingServices-one-box-img1" data-aos="fade-up" data-aos-duration="3000">  
                <h2>Premier Print Solutions</h2>
                <p className="pb-3">A trusted printing company delivering high-quality offset and digital printing with exceptional clarity and vibrant colors. Specializing in brochures, books, custom packaging, and tailored designs, we ensure quick turnaround times without compromising quality. Committed to eco-friendly practices, we use sustainable materials and energy-efficient processes. Our customer-focused team offers expert advice and personalized service for a seamless printing experience.</p>
              </div>
            </Col>
            <Col lg={4}>
              <div className="printingServices-one-box-img2"  data-aos="fade-up" data-aos-duration="3000">     
                <img src={require('../assets/img/printing-services-1.jpg')} className="img-fluid" alt="elixir" />
              </div>
            </Col>
            <Col lg={8} className="pt-4">   
              <div className="printingServices-one-box-text"  data-aos="fade-up" data-aos-duration="3000">       
                <h4><span>1</span> Offset and Digital Printing Experts</h4>
                <p>Providing premium printing quality with advanced offset and digital technology for vibrant, precise results. Catering to personal and corporate needs, we ensure expert craftsmanship, innovative designs, and quick turnaround times.</p>
              </div>
              <div className="printingServices-one-box-text" data-aos="fade-up" data-aos-duration="3000">
                <h4><span>2</span> Packaging and Custom Design Solutions</h4>
                <p>We create innovative, functional packaging tailored to your brand, blending aesthetics with precision and creativity. From retail to luxury goods, our unique designs and quality materials ensure your products make a lasting impression.</p>
              </div>
              <div className="printingServices-one-box-text" data-aos="fade-up" data-aos-duration="3000">
                <h4><span>3</span> Eco-Friendly and Customer-Centric Service</h4>
                <p>Committed to sustainable printing, we use eco-friendly materials and energy-efficient processes while delivering high-quality results. Our dedicated team prioritizes your needs, offering personalized service to exceed expectations and minimize environmental impact.</p>
              </div>   
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
      <Container>
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div className="projectTab-box">
              <img src={require('../assets/img/project/digital/digital-project-4.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Digital Brand 4</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Printingservices;