import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Brandingandgraphicdesign() {
  useEffect(()=>{
    document.title = 'Elixir | Branding and Graphic Design';
  }, []);
  return (
    <>
    <section className="brandServices">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Designing <span className="span2 home-banner-text-animate">Your Brand</span> Story Concept to Creation</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">In today’s competitive market, crafting a memorable brand story is essential for connecting with your audience and standing out from the crowd. Elixir, a leader in innovative branding solutions, presents the ultimate guide for businesses, entrepreneurs, and creatives looking to define and elevate their brand identity.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/branding-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="brandServices-one">
      <Container>
        <div className="brandServices-one-box">
          <Row>  
            <Col lg={3}>
              <h4 data-aos="fade-up" data-aos-duration="3000">Creative Branding & Graphic Design</h4>
              <p data-aos="fade-up" data-aos-duration="3000">Transforming ideas into stunning visuals, we design unique logos, brand identities, and promotional graphics. Whether launching a business or reimagining your brand, we craft bold, memorable designs!</p>
            </Col> 
            <Col lg={6}>
              <img src={require('../assets/img/branding-services-1.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
            </Col>      
            <Col lg={3}>
            <h4 data-aos="fade-up" data-aos-duration="3000">From Vision to Visuals Designing Stories</h4>
              <ul data-aos="fade-up" data-aos-duration="3000">
                <li>Define your brand's mission, vision, and values.</li>
                <li>Create compelling visual elements, from logos to typography.</li>
                <li>Leverage the latest graphic design trends and tools.</li>              
              </ul>
            </Col>          
          </Row>
        </div>
      </Container>
    </section>
    <section className="brandServices-two">
      <Container>
        <div className="brandServices-two-box">
          <Row> 
            <Col lg={3} md={6}>
              <img src={require('../assets/img/branding-services-2.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
            </Col>
            <Col lg={3} md={6}>
              <img src={require('../assets/img/branding-services-3.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
            </Col>
            <Col lg={6}>
              <div className="brandServices-two-text">
                <h3 data-aos="fade-up" data-aos-duration="3000">Bold Branding & Design Studio</h3>
                <p data-aos="fade-up" data-aos-duration="3000">We create stunning visuals that capture your brand’s unique identity and tell your story. From memorable logos to cohesive graphic designs, we bring your vision to life with creativity and precision. Our designs ensure your brand stands out, resonates with your audience, and leaves a lasting impression. Let’s craft a brand that inspires, connects, and drives success!</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
      <Container>
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div className="projectTab-box">
              <img src={require('../assets/img/project/digital/digital-project-1.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Digital Brand 1</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Brandingandgraphicdesign;
