import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/style.css";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Navigation, Footer, Home, Aboutus, Contactus, Brandingandgraphicdesign, Signageoutdoor, Signageindoor, Project, Clients, Printingservices, Eventservices, Exhibitionservices, Corporateservices, Webdesignservices, Tradingservices } from "./components";
import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init();

ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      {/* <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<Aboutus />} />      
      <Route path="/digitalconsultancy" element={<Digitalconsultancy />} />
      <Route path="/project" element={<Project />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contactus" element={<Contactus />} /> */}
      <Route path="/elixir-new-1" element={<Home />} />
      <Route path="/elixir-new-1/aboutus" element={<Aboutus />} />      
      <Route path="/elixir-new-1/brandingandgraphicdesign" element={<Brandingandgraphicdesign />} />
      <Route path="/elixir-new-1/Signageoutdoor" element={<Signageoutdoor />} />
      <Route path="/elixir-new-1/signageindoor" element={<Signageindoor />} />

      <Route path="/elixir-new-1/printingservices" element={<Printingservices />} />
      <Route path="/elixir-new-1/eventservices" element={<Eventservices />} />
      <Route path="/elixir-new-1/exhibitionservices" element={<Exhibitionservices />} />
      <Route path="/elixir-new-1/corporateservices" element={<Corporateservices />} />
      <Route path="/elixir-new-1/webdesignservices" element={<Webdesignservices />} />
      <Route path="/elixir-new-1/tradingservices" element={<Tradingservices />} />

      <Route path="/elixir-new-1/project" element={<Project />} />
      <Route path="/elixir-new-1/clients" element={<Clients />} />
      <Route path="/elixir-new-1/contactus" element={<Contactus />} />
      <Route path="*" element={<Navigate to="/elixir-new-1" />} />
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);

