import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Contactus() {

  useEffect(()=>{
    document.title = 'Elixir | Contact Us';
  }, []);

  return (
    <>
    <section className="contactSec">
      <Container>
        <Row>              
          <Col lg={6}>
            <img src={require('../assets/img/contact-inner-1.jpg')} className="img-fluid" alt="elixir" data-aos="fade-up" data-aos-duration="3000"  />
          </Col>
          <Col lg={3}>
            <img src={require('../assets/img/contact-inner-2.jpg')} className="img-fluid" alt="elixir" data-aos="fade-up" data-aos-duration="3000"  />
          </Col>
          <Col lg={3}>
            <img src={require('../assets/img/contact-inner-3.jpg')} className="img-fluid" alt="elixir" data-aos="fade-up" data-aos-duration="3000"  />
          </Col>              
        </Row>
      </Container>
    </section>
    <section className="contactForm">
      <Container>
        <div className="contactForm-box">
          <Row data-aos="fade-up" data-aos-duration="3000">
            <Col lg={12}>
              <h2>Share a <span className="span2 home-banner-text-animate">Little Spark</span> of Inspiration with you!</h2>
            </Col>
            <Col lg={4}>
              <div className="contactForm-address">
                <h4>Address</h4>
                <p><span>Off:</span> P.O.Box:2834, Alkhobar 31952,</p>
                <p><span>Prod.Off:</span> Dammam 31463, Saudi Arabia</p>
                <p><span>Mobile:</span> <a href="tel:+966552301809">+966 55 230 1809</a></p>
                <h4>Mail</h4>
                <p><span>Enquiry:</span> <a href="mailto:Anand@elixirworldwide.com">anand@elixirworldwide.com</a></p>
                <p><span>Mail:</span> <a href="mailto:elixirworldwide@gmail.com">elixirworldwide@gmail.com</a></p>
                <p><span>Job:</span> <a href="mailto:jobs@elixirworldwide.com">jobs@elixirworldwide.com</a></p>
              </div>
            </Col>
            <Col lg={8}>
              <Form>
                <Form.Group className="mb-4" controlId="formBasicPassword">
                  <Form.Control type="text" placeholder="Name" />
                </Form.Group>

                <Row className="mb-4">
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control type="text" placeholder="Mobile" />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control type="email" placeholder="Email" />
                  </Form.Group>
                </Row>

                <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={4} placeholder="Message" />
                </Form.Group>
                <div className="text-end pt-3">
                  <Button className="primaryBtn" type="submit">
                    Submit
                  </Button>  
                </div>                
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    </>
  );
}

export default Contactus;
