import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Eventservices() {
  useEffect(()=>{
    document.title = 'Elixir | Event Management - Services';
  }, []);
  return (
    <>
    <section className="brandServices">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Memorable and <span className="span2 home-banner-text-animate">Unique Events</span> Creative Designs</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">Crafting exceptional events with creative designs tailored to your unique vision and style. We focus on every detail, blending innovation, elegance, and functionality to create unforgettable experiences. From planning to flawless execution, we ensure your event stands out, exceeds expectations, and leaves a lasting impression.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/events-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="eventsServices-one">
      <Container>
        <Row>
          <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
            <h3>Captivating Events, Crafted to Perfection</h3>
            <p>We specialize in designing and executing stunning events that leave a lasting impression on you and your guests. Whether it’s an intimate gathering or a grand celebration, we take care of every detail to bring your vision to life. From conceptualizing unique themes and selecting the perfect venue to curating elegant décor and managing the day’s schedule, we ensure a seamless and stress-free experience. Our expert team combines creativity with precision, making your event not only memorable but truly extraordinary.</p>
          </Col>
          <Col lg={6}>
            <img src={require('../assets/img/events-services-img-1.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
          </Col>
        </Row>
      </Container>
    </section>
    <section className="eventsServices-two">
      <Container>
        <div className="eventsServices-two-box">
          <Row>
            <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
              <h3>Unforgettable Experiences, Personalized for You</h3>
              <p>Our mission is to create events that perfectly capture your style and personality, leaving lasting memories for you and your guests. We work hand-in-hand with you to bring your vision to life, offering a personalized approach for every celebration. From venue selection and customized décor to entertainment, catering, and overall event coordination, we handle everything with meticulous care. Whether it's a wedding, corporate event, or social gathering, we aim to exceed your expectations and make your event truly one of a kind.</p>
            </Col>
            <Col lg={6}>
              <img src={require('../assets/img/events-services-img-2.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
      <Container>
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div className="projectTab-box">
              <img src={require('../assets/img/project/digital/digital-project-1.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Digital Brand 1</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Eventservices;