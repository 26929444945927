import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Signageoutdoor() {
  useEffect(()=>{
    document.title = 'Elixir | Signage - Outdoor';
  }, []);
  return (
    <>
    <section className="outdoorServices">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Your Brand in the Spotlight <span className="span2 home-banner-text-animate">Outdoor Signage</span> Units</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">
            Make a lasting impression with high-quality outdoor signage that captures attention and withstands the elements. From illuminated displays to large-scale units, our customizable solutions ensure your brand shines day and night, eye-catching making your message stand out in any environment.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/outdoor-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="outdoorServices-one">
      <Container>
        <div className="outdoorServices-one-box">
          <Row>          
            <Col lg={6}>
              <h4 data-aos="fade-up" data-aos-duration="3000">The Ultimate Outdoor Signage Experience</h4>
              <p data-aos="fade-up" data-aos-duration="3000">Showcase your brand with outdoor signage designed to stand out in any setting. Crafted to endure the elements, our signage solutions deliver bold, vibrant visibility whether day or night. From sleek illuminated displays to large-scale custom signage, we provide tailored options that highlight your brand. Ideal for storefronts, events, or outdoor spaces, our expertly crafted units ensure your message is always front and center. Let us help you create a striking impression that lasts!</p>
            </Col>
            <Col lg={6}>
              <img src={require('../assets/img/outdoor-services-1.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="outdoorServices-two">
      <Container>
        <div className="outdoorServices-two-box">
          <Row>    
            <Col lg={6}>
              <img src={require('../assets/img/outdoor-services-2.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
            </Col>      
            <Col lg={6}>
              <h4 data-aos="fade-up" data-aos-duration="3000">Powerful Outdoor Signage for Maximum Impact</h4>
              <p data-aos="fade-up" data-aos-duration="3000">Elevate your brand's visibility with our outdoor signage solutions designed for maximum impact. From illuminated displays to large-scale custom units, our signage provides bold, vibrant visibility day and night, enduring the elements. Perfect for storefronts, events, or outdoor spaces, it ensures your brand stands out and makes a lasting impression. Durable, eye-catching, and expertly crafted, our signage helps you stay front and center with your audience.</p>
            </Col>          
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
          <Container>
            <Row>
              <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
              <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                <div className="projectTab-box">
                  <img src={require('../assets/img/project/digital/digital-project-2.jpg')} className="img-fluid" alt="elixir" />
                  <div className="projectTab-box-content">
                    <h4>Digital Brand 2</h4>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
                <div className="projectTab-box">
                  <img src={require('../assets/img/project/digital/digital-project-3.jpg')} className="img-fluid" alt="elixir" />
                  <div className="projectTab-box-content">
                    <h4>Digital Brand 3</h4>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
    </section>
    </>
  );
}

export default Signageoutdoor;
