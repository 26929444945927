import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Webdesignservices() {
  useEffect(()=>{
    document.title = 'Elixir | Website Design - Services';
  }, []);
  return (
    <>
    <section className="brandServices">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Professional <span className="span2 home-banner-text-animate">Website</span> Design and Development Services</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">Enhance your online presence with our expert website design and development services. We create user-friendly, responsive sites tailored to your business goals, ensuring functionality, optimization, and engagement to drive results.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/website-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="eventsServices-one">
      <Container>
        <Row>
          <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
            <h3>Expert Website Design & Development Services</h3>
            <p>Enhance your online presence with our professional website design and development services. We create responsive, user-friendly websites tailored to your business needs, ensuring optimal performance and engagement. From concept to launch, we deliver custom solutions that drive results and support your business growth. Trust us to provide a seamless digital experience that elevates your brand and connects with your audience.</p>
          </Col>
          <Col lg={6}>
            <img src={require('../assets/img/website-services-img-1.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
          </Col>
        </Row>
      </Container>
    </section>
    <section className="eventsServices-two">
      <Container>
        <div className="eventsServices-two-box">
          <Row>
            <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
              <h3>Custom Web Solutions for Business Success</h3>
              <p>Transform your business with our custom website solutions, tailored to meet your brand's unique needs. We create visually stunning, responsive websites optimized for performance, speed, and user experience. Whether you need an e-commerce platform, portfolio, or business site, we provide solutions that align with your goals. Our team ensures your website reflects your vision, offering seamless navigation, powerful functionality, and effective engagement strategies to grow your online presence.</p>
            </Col>
            <Col lg={6}>
              <img src={require('../assets/img/website-services-img-2.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
      <Container>
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
          <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="3000">
            <a href="#" className="projectTab-box">
              <img src={require('../assets/img/project/websites/websites-project-1.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Websites 1</h4>
              </div>
            </a>
          </Col>
          <Col lg={4} md={6} data-aos="fade-up" data-aos-duration="3000">
            <a href="#" className="projectTab-box">
              <img src={require('../assets/img/project/websites/websites-project-2.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Websites 2</h4>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Webdesignservices;