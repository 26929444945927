import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Tradingservices() {
  useEffect(()=>{
    document.title = 'Elixir | Trading - Services';
  }, []);
  return (
    <>
    <section className="brandServices">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Reliable <span className="span2 home-banner-text-animate">Trading Machinery</span> Printing Solutions</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">Enhance your business or event with our printing services and top-tier event machinery, We offer printing products, including business cards, brochures, projectors, and sound systems, ensuring a seamless and professional experience. Our trading services provide the tools and equipment.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/trading-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="eventsServices-one">
      <Container>
        <Row>
          <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
            <h3>Premium Printing and Event Equipment Solutions</h3>
            <p>Elevate your business or event with our high-quality printing services and professional event equipment. We offer a range of printing products like business cards, brochures, and custom materials, along with top-tier event machines such as projectors, sound systems, and more. Our comprehensive solutions ensure a seamless and impactful experience for any occasion.</p>
          </Col>
          <Col lg={6}>
            <img src={require('../assets/img/trading-services-img-1.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
          </Col>
        </Row>
      </Container>
    </section>
    <section className="eventsServices-two">
      <Container>
        <div className="eventsServices-two-box">
          <Row>
            <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
              <h3>Comprehensive Printing and Event Machinery Trading</h3>
              <p>Make your business or event stand out with our expert printing and event machinery services. From printed materials like flyers and banners to essential event equipment like projectors and audio systems, we provide everything you need to create a professional and successful experience. Count on us for reliable products and exceptional service.</p>
            </Col>
            <Col lg={6}>
              <img src={require('../assets/img/trading-services-img-2.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
      <Container>
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div className="projectTab-box">
              <img src={require('../assets/img/project/digital/digital-project-8.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Digital Brand 8</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Tradingservices;