import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Exhibitionservices() {
  useEffect(()=>{
    document.title = 'Elixir | Exhibition Booths - Services';
  }, []);
  return (
    <>
    <section className="brandServices">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Comprehensive <span className="span2 home-banner-text-animate">Exhibition Stall</span> Support Services</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">From concept to execution, we provide comprehensive support for your exhibition stalls. Our services include design, setup, branding, and on-site assistance to ensure your presence stands out. Whether you’re showcasing products, services, or innovations, we help create an engaging and professional.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/exhibition-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="eventsServices-one">
      <Container>
        <Row>
          <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
            <h3>End-to-End Exhibition Stall Solutions</h3>
            <p>Elevate your brand presence with our full-service exhibition stall solutions. From concept to setup, we offer a seamless experience covering all your exhibition needs. Our expert team specializes in creative stall designs that reflect your brand’s identity, professional fabrication ensuring quality and durability, and meticulous on-site setup for a hassle-free experience. We also provide branding assistance, lighting solutions, and on-site support to ensure everything runs smoothly. Whether you’re launching a product, showcasing services, or building industry connections.</p>
          </Col>
          <Col lg={6}>
            <img src={require('../assets/img/exhibition-services-img-1.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
          </Col>
        </Row>
      </Container>
    </section>
    <section className="eventsServices-two">
      <Container>
        <div className="eventsServices-two-box">
          <Row>
            <Col lg={6} data-aos="fade-up" data-aos-duration="3000">
              <h3>Professional Exhibition Stall Design and Support</h3>
              <p>Make a lasting impact at your next exhibition with our professional stall design and support services. We create visually striking spaces that capture attention and communicate your brand’s message effectively. Our services include custom designs, premium-quality fabrication, and complete setup to ensure perfection. We also provide branding solutions, interactive features, and on-site support to handle any adjustments, transforming your presence into a powerful marketing opportunity.</p>
            </Col>
            <Col lg={6}>
              <img src={require('../assets/img/exhibition-services-img-2.jpg')} data-aos="zoom-in" data-aos-duration="3000" className="img-fluid" alt="elixir" />   
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
      <Container>
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div className="projectTab-box">
              <img src={require('../assets/img/project/digital/digital-project-5.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Digital Brand 5</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Exhibitionservices;