import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function Signageindoor() {
  useEffect(()=>{
    document.title = 'Elixir | Signage - Indoor';
  }, []);
  return (
    <>
    <section className="outdoorServices">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 data-aos="zoom-in" data-aos-duration="3000">Eye-Catching Custom <span className="span2 home-banner-text-animate">Indoor Signage</span> for Businesses</h1>
          </Col>
          <Col lg={6}>
            <p data-aos="fade-up" data-aos-duration="3000">Enhance your space with vibrant, customizable indoor signage that grabs attention and communicates your brand effectively. Perfect for retail stores, offices, events, and more. Durable, stylish, weather-resistant, and designed to leave a lasting impression effortlessly.</p>
          </Col>
        </Row>
      </Container>
    </section>
    <section className="servicesBanner">
      <Container>
        <div className="servicesBanner-box">
          <Row>  
            <div className="brandServices-one-box-img" data-aos="fade-up" data-aos-duration="3000">
              <img src={require('../assets/img/indoor-services-banner.jpg')} className="img-fluid" alt="elixir" />   
            </div>      
          </Row>
        </div>
      </Container>
    </section>
    <section className="outdoorServices-one">
      <Container>
        <div className="outdoorServices-one-box">
          <Row>       
            <Col lg={6}>
              <img src={require('../assets/img/indoor-services-1.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
            </Col>   
            <Col lg={6}>
              <h4 data-aos="fade-up" data-aos-duration="3000">Transform Spaces with Stunning Indoor Signage</h4>
              <p data-aos="fade-up" data-aos-duration="3000">Elevate your interiors with premium indoor signage that captivates and communicates. Designed for retail, offices, and events, our signage is durable, customizable, and crafted to highlight your brand's identity in any setting. With unmatched quality, vibrant designs, and expert craftsmanship, make a bold statement that truly stands out.</p>
            </Col>              
          </Row>
        </div>
      </Container>
    </section>
    <section className="outdoorServices-two">
      <Container>
        <div className="outdoorServices-two-box">
          <Row>   
            <Col lg={6}>
              <h4 data-aos="fade-up" data-aos-duration="3000">Vibrant Indoor Signage to Boost Visibility</h4>
              <p data-aos="fade-up" data-aos-duration="3000">Make your message unforgettable with high-quality indoor signage. Perfect for businesses, events, or personal projects, our signs are tailored to your needs, blending durability with a modern aesthetic to impress every viewer. Available in various styles, sizes, and finishes, our signage ensures your brand stands out with sophistication.</p>
            </Col>  
            <Col lg={6}>
              <img src={require('../assets/img/indoor-services-2.jpg')} className="img-fluid" alt="elixir" data-aos="zoom-in" data-aos-duration="3000" />
            </Col>  
          </Row>
        </div>
      </Container>
    </section>
    <section className="caseStudies">
      <Container>
        <Row>
          <h3 data-aos="fade-up" data-aos-duration="3000">Case Studies</h3> 
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div className="projectTab-box">
              <img src={require('../assets/img/project/digital/digital-project-2.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Digital Brand 2</h4>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="3000">
            <div className="projectTab-box">
              <img src={require('../assets/img/project/digital/digital-project-3.jpg')} className="img-fluid" alt="elixir" />
              <div className="projectTab-box-content">
                <h4>Digital Brand 3</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default Signageindoor;